import { AxiosRequestConfig } from 'axios';
import { UpdateDepartment, UpdateDepartmentLocation } from '../types';

const endpoint = 'api/departments';

export default {
    retrieveLocation: (id: number) => ({ method: 'GET', url: `${endpoint}/${id}/location` } as AxiosRequestConfig),
    updateDepartment: (id: number, data: UpdateDepartment) =>
        ({ method: 'PUT', url: `${endpoint}/${id}`, data } as AxiosRequestConfig),
    updateDepartmentLocation: (id: number, data: UpdateDepartmentLocation) =>
        ({ method: 'PUT', url: `${endpoint}/${id}/location`, data } as AxiosRequestConfig),
};
