





































































































































































































































































































import { defineComponent, ref, computed } from '@vue/composition-api';
import { useAxios } from '@vue-composable/axios';
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate';
import { required, email, confirmed, min, max } from 'vee-validate/dist/rules';
import { useQuery, useResult } from '@/app/composable';
import Keycloak from '@/modules/auth/api/keycloak';
import store from '@/app/store';
import Auth from '@/modules/auth/api/auth';
import { TwButton } from '@/app/components';
import { CountriesAPI, OrganizationsAPI } from '@/modules/organization/api';
import GET_COUNTRIES from '../graphql/getCountries.graphql';
import GET_TYPES from '../graphql/getTypes.graphql';
import { CreateOrganization } from '../types';

extend('required', required);
extend('termsAndConditionsIsRequired', {
    ...required,
    message: 'You need to read and accept the terms and conditions before proceeding',
});
extend('managerConfirmationIsRequired', {
    ...required,
    message: 'You need to confirm that you are the Manager of this organization before proceeding',
});
extend('email', email);
extend('confirmed', { ...confirmed, message: 'Repeat Password does not match.' });
extend('min', { ...min, message: 'Password must be longer than or equal to 8 characters.' });
extend('max', {
    ...max,
    message: 'Maximum length exceeded.',
});

export default defineComponent({
    name: 'OrganizationRegistration',
    components: { TwButton, ValidationProvider, ValidationObserver },
    setup(props, { root }) {
        const { exec } = useAxios(true);

        const { result: resultCountries } = useQuery(GET_COUNTRIES, {}, { fetchPolicy: 'no-cache' });
        const countries = useResult(resultCountries, [], (data: any) => data.countries);
        const sortedCountries = computed(() => countries.value.sort((a: any, b: any) => a.name.localeCompare(b.name)));

        const { result: resultTypes } = useQuery(GET_TYPES, {}, { fetchPolicy: 'no-cache' });
        const types = useResult(resultTypes, [], (data: any) => data.organisationTypes);
        const sortedTypes = computed(() => types.value.sort((a: any, b: any) => a.name.localeCompare(b.name)));

        const cities = ref<any>([]);

        const loading = ref(false);
        const message = ref<any>(null);
        const showMessage = ref(false);
        const enableTermsAndConditions = false; // turn to true if you want to show terms and conditions component
        const managerConfirmation = false;

        const organizationDataTemplate = ref<CreateOrganization>({
            legalName: '',
            businessName: '',
            address: '',
            postalCode: '',
            website: '',
            description: '',
            cityId: null,
            countryId: null,
            continentId: null,
            typeId: null,
        });

        const continentIdOfSelectedCountry = computed<number>(() =>
            organizationDataTemplate.value.countryId
                ? sortedCountries.value.filter((c: any) => c.id === organizationDataTemplate.value.countryId)[0]
                      .continent.id
                : null,
        );

        if (enableTermsAndConditions) {
            // eslint-disable-next-line dot-notation
            organizationDataTemplate.value['termsAndConditions'] = false;
        }

        const getCities = (id: any) => {
            loading.value = true;
            exec(CountriesAPI.retrieveCitiesOfCountry(id))
                .then((res: any) => {
                    cities.value = res.data.sort((a: any, b: any) => a.name.localeCompare(b.name));
                    organizationDataTemplate.value.cityId = null;
                    // loading.value = false;
                })
                .finally(() => {
                    loading.value = false;
                });
        };

        const registerOrganization = () => {
            loading.value = true;
            organizationDataTemplate.value.continentId = continentIdOfSelectedCountry.value;
            exec(OrganizationsAPI.registerOrganization(organizationDataTemplate.value))
                .then(async () => {
                    root.$router.push({ name: 'unverified-organization' }); // redirect to Your organization is pending approval Page.
                    loading.value = false;
                })
                .catch((e: any) => {
                    if (e.response && e.response.data && e.response.data.message) {
                        switch (e.response.data.message) {
                            case 'Unauthorized':
                                (root as any).$toastr.e('Unauthorized action', 'Error');
                                break;
                            case 'Registration is closed':
                                message.value = 'Registration is closed';
                                showMessage.value = true;
                                break;
                            case 'User is already in an organisation.':
                                (root as any).$toastr.e('User is already in an organization.', 'Error');
                                break;
                            default:
                                message.value =
                                    e.response.data.message && e.response.data.message[0].includes('website')
                                        ? 'Website must be a valid URL'
                                        : 'Legal Name already exists.';
                                showMessage.value = true;
                        }
                    }

                    loading.value = false;
                });
        };

        const isRegistrationOpen = computed(() => message.value !== 'Registration is closed');

        const legalNameAlreadyExists = computed(
            () => isRegistrationOpen.value && message.value && message.value.includes('Legal'),
        );
        const websiteIsNotValid = computed(
            () => isRegistrationOpen.value && message.value && message.value.includes('Website'),
        );

        const logout = async () => {
            (root as any).$toastr.removeByType('info');
            store.commit.auth.CLEAR_USER();
            store.commit.notificationEngine.CLEAR_NOTIFICATIONS();
            // await Keycloak.logout();
            await Auth.logout();
            root.$router.push({ name: 'home' });
        };

        return {
            enableTermsAndConditions,
            loading,
            message,
            showMessage,
            registerOrganization,
            isRegistrationOpen,
            sortedCountries,
            organizationDataTemplate,
            getCities,
            cities,
            sortedTypes,
            continentIdOfSelectedCountry,
            managerConfirmation,
            logout,
            legalNameAlreadyExists,
            websiteIsNotValid,
        };
    },
    async beforeRouteEnter(to: any, from: any, next: any) {
        if (store.getters.auth.isAuthenticated) {
            return next();
        }
        try {
            const { data: user } = await Auth.user();
            store.commit.auth.SET_USER(user);
            if (to.meta?.feature && !store.getters.auth.isEnabled(to.meta.feature)) {
                return next({ name: '404' });
            }
            return next();
        } catch (e) {
            return Keycloak.login(to.name);
        }
    },
});
