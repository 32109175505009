















































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import * as R from 'ramda';
import { defineComponent, ref, computed, watchEffect, watch } from '@vue/composition-api';
import { OrbitSpinner } from 'epic-spinners';
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate';
import { required, max, email } from 'vee-validate/dist/rules';
import { useAxios } from '@vue-composable/axios';
import { useQuery, useResult } from '@/app/composable';
import { FormBlock, TwButton, ConfirmModal, Tabs, Scrollbar } from '@/app/components';
import store from '@/app/store';
import { OrganizationsAPI, CountriesAPI, DepartmentsAPI, OrganizationRolesAPI, UsersAPI, KeycloakAPI } from '../api';
import GET_COMPLETE_ORGANIZATION from '../graphql/getCompleteOrganization.graphql';
import GET_ORGANIZATION_USERS from '../graphql/getOrganizationUsers.graphql';
import GET_COUNTRIES from '../graphql/getCountries.graphql';

extend('required', {
    ...required,
    message: '{_field_} is required',
});

extend('email', email);

extend('max', {
    ...max,
    message: 'Maximum length exceeded.',
});

export default defineComponent({
    name: 'EditOrganization',
    components: {
        FormBlock,
        ValidationObserver,
        ValidationProvider,
        OrbitSpinner,
        TwButton,
        ConfirmModal,
        Tabs,
        Scrollbar,
    },
    setup(props, { root }) {
        const showDeleteModal = ref(false);
        const toBeDeleted = ref<number | null>(null);
        const enableDepartmentsInfo = ref(false);
        const currentOrg = ref<any>(null);

        watch(showDeleteModal, (value) => {
            if (!value) toBeDeleted.value = null;
        });

        const user = ref(store.state.auth.user);
        const readOnly = ref(!store.state.auth.user.roles.includes('manager'));
        const departments = ref([]);
        const newDepartmentRef = ref<any>(null);
        const updateDepartmentRef = ref<any>(null);
        const organizationRef = ref<any>(null);
        const inviteUserError = ref<string>('');

        const newDepartment = ref({
            name: '',
            description: '',
            address: '',
            postalCode: '',
            countryId: null,
            cityId: null,
            continentId: 0,
            locationId: 0,
        });

        const updateDepartmentDetails = ref({
            id: 0,
            name: '',
            description: '',
            address: '',
            postalCode: '',
            countryId: null,
            cityId: null,
            continentId: 0,
        });

        const userInvitation = ref({
            firstName: '',
            lastName: '',
            email: '',
            username: '', // will be temporarily identical with email
            // departments are disabled for now
            // departmentId: null,
            // roleId: null,
        });

        const tabs = ref([
            { id: 0, title: 'Overview' },
            { id: 1, title: 'Members' },
            // { id: 2, title: 'Preferences' },
        ]);
        const activeTab = ref(0);

        const addDepHidden = ref(false);
        const inviteUserHidden = ref(false);
        const updateDepHidden = ref(false);

        const { exec, loading, error } = useAxios(true);

        const {
            onResult,
            loading: organizationLoading,
            error: organizationError,
            result,
            refetch: refetchOrg,
        } = useQuery(
            GET_COMPLETE_ORGANIZATION,
            { id: user.value.organisationId ? user.value.organisationId : 0 },
            { fetchPolicy: 'no-cache' },
        );

        const orgLocationCities = ref([]);
        onResult((resultOrg: any) => {
            exec(CountriesAPI.retrieveCitiesOfCountry(resultOrg.organisation.location.country.id)).then((res: any) => {
                orgLocationCities.value = res.data.sort((a: any, b: any) => a.name.localeCompare(b.name));
            });

            currentOrg.value = R.clone(resultOrg.data.organisation);
        });

        const completeOrganization = useResult(result, null, (data: any) => data.organisation);

        const changesExist = computed(() => {
            completeOrganization.value.location.city.id = completeOrganization.value.location.city.id.toString();

            if (JSON.stringify(currentOrg.value) !== JSON.stringify(completeOrganization.value)) {
                return true;
            }

            return false;
        });

        const {
            loading: organizationUsersLoading,
            error: organizationUsersError,
            result: resultOrgUsers,
            refetch,
        } = useQuery(
            GET_ORGANIZATION_USERS,
            { id: user.value.organisationId ? user.value.organisationId : 0 },
            { fetchPolicy: 'no-cache' },
        );

        const organization = useResult(resultOrgUsers);
        const organizationUsers = useResult(resultOrgUsers, null, (data: any) => data.organisation.users);
        const organizationDepartments = useResult(resultOrgUsers, null, (data: any) => data.organisation.departments);

        const { loading: countriesLoading, error: countriesError, result: resultCountries } = useQuery(
            GET_COUNTRIES,
            {},
            { fetchPolicy: 'no-cache' },
        );

        const countries = useResult(resultCountries, [], (data: any) => data.countries);
        const sortedCountries = computed(() => countries.value.sort((a: any, b: any) => a.name.localeCompare(b.name)));

        const cities = ref<any>([]);
        const depUsers = ref<any>([]);
        const bool = ref(true);

        // methods
        const getCities = (id: any) => {
            exec(CountriesAPI.retrieveCitiesOfCountry(id)).then((res: any) => {
                cities.value = res.data;
                cities.value = cities.value.sort((a: any, b: any) => a.name.localeCompare(b.name));
            });

            newDepartment.value.cityId = null;
            loading.value = false;
        };

        const organizationRoles = ref([]);
        exec(OrganizationRolesAPI.retrieveOrgRoles())
            .then((res: any) => {
                organizationRoles.value = res.data;
            })
            .catch(() => {
                (root as any).$toastr.e('An error has occured', 'Error');
            });

        const saveChanges = async () => {
            const valid = await organizationRef.value.validate();

            if (valid) {
                const updatedOrg = completeOrganization.value;

                const payload = {
                    businessName: updatedOrg.businessName,
                    description: updatedOrg.description,
                    address: updatedOrg.location.address,
                    postalCode: updatedOrg.location.postalCode,
                    cityId: parseInt(updatedOrg.location.city.id, 10),
                    website: updatedOrg.website,
                };

                try {
                    await exec(OrganizationsAPI.update(completeOrganization.value.id, payload));
                    refetchOrg();
                    (root as any).$toastr.Add({
                        // name: 'test',
                        title: 'Success',
                        msg: 'Organisation Details have been changed successfuly',
                        type: 'success',
                        timeout: 5000,
                        preventDuplicates: true,
                    });
                } catch (e) {
                    (root as any).$toastr.e('Editing organisation details failed', 'Error');
                }
            }
        };

        const cancel = async () => {
            root.$router.go(-1);
        };

        const addDepartment = () => {
            addDepHidden.value = !addDepHidden.value;
        };

        const inviteUser = () => {
            inviteUserHidden.value = !inviteUserHidden.value;
        };

        const createDepartment = () => {
            const valid = newDepartmentRef.value.validate();

            if (valid) {
                const country = sortedCountries.value.find((x: any) => x.id === newDepartment.value.countryId);
                newDepartment.value.continentId = country.continent.id;
                exec(OrganizationsAPI.createDepartment(completeOrganization.value.id, newDepartment.value as any))
                    .then(() => {
                        refetch();

                        newDepartment.value = {
                            name: '',
                            description: '',
                            address: '',
                            postalCode: '',
                            countryId: null,
                            cityId: null,
                            continentId: 0,
                            locationId: 0,
                        };
                        addDepHidden.value = false;

                        (root as any).$toastr.s('Department has been created successfuly', 'Success');
                    })
                    .catch(() => {
                        (root as any).$toastr.e('The department could not be created due to an error.', 'Error');
                    });
            }
        };

        watchEffect(() => {
            if (organizationDepartments.value && organizationUsers.value) {
                if (bool.value) {
                    organizationUsers.value.forEach((tmpUser: any) => {
                        organizationDepartments.value.forEach((dep: any) => {
                            dep.users.forEach((depUser: any) => {
                                if (depUser.user.id === tmpUser.id) {
                                    depUsers.value.push({
                                        id: tmpUser.id,
                                        role: depUser.role.name,
                                        departmentName: dep.name,
                                    });
                                }
                            });
                        });
                    });

                    bool.value = false;
                }
            }
        });

        const editDepartment = async (
            id: number,
            name: string,
            description: string,
            address: string,
            postalCode: string,
            cityId: any,
            countryId: any,
        ) => {
            updateDepHidden.value = true;
            updateDepartmentDetails.value = {
                id,
                name,
                description,
                address,
                postalCode,
                cityId,
                countryId,
                continentId: 0,
            };
            getCities(countryId);
        };

        const updateDepartment = async () => {
            const valid = updateDepartmentRef.value.validate();

            if (valid) {
                const country = sortedCountries.value.find(
                    (x: any) => x.id === updateDepartmentDetails.value.countryId,
                );

                const departmentPayload = {
                    name: updateDepartmentDetails.value.name,
                    description: updateDepartmentDetails.value.description,
                };
                const departmentLocationPayload = {
                    address: updateDepartmentDetails.value.address,
                    postalCode: updateDepartmentDetails.value.postalCode,
                    countryId: updateDepartmentDetails.value.countryId,
                    cityId: updateDepartmentDetails.value.cityId,
                    continentId: country.continent.id,
                };

                exec(DepartmentsAPI.updateDepartment(updateDepartmentDetails.value.id, departmentPayload as any))
                    .then(() => {
                        exec(
                            DepartmentsAPI.updateDepartmentLocation(
                                updateDepartmentDetails.value.id,
                                departmentLocationPayload as any,
                            ),
                        ).then(() => {
                            refetch();

                            (root as any).$toastr.s('Department details have been updated successfuly', 'Success');
                        });
                    })
                    .catch(() => {
                        (root as any).$toastr.e('The department could not be updated due to an error.', 'Error');
                    });
                updateDepHidden.value = false;
            }
        };

        const removeDepartment = async () => {
            if (toBeDeleted.value) {
                try {
                    const { exec: execDelete } = useAxios(true);
                    await execDelete(
                        OrganizationsAPI.removeDepartment(completeOrganization.value.id, toBeDeleted.value as any),
                    );

                    (root as any).$toastr.s('Department has been deleted successfuly', 'Success');
                    refetch();
                } catch (e) {
                    (root as any).$toastr.e(
                        'The department could not be deleted due to an error. Be sure that there are no users in the department.',
                        'Error',
                    );
                }

                showDeleteModal.value = false;
            }
        };

        const confirmDelete = (id: number) => {
            toBeDeleted.value = id;
            showDeleteModal.value = true;
        };

        const showDepartmentsInfo = computed(() => {
            if (completeOrganization.value) {
                if (addDepHidden.value && !completeOrganization.value.departments.length) {
                    return false;
                }
                if (completeOrganization.value.departments.length) {
                    return true;
                }
            }
            return false;
        });

        const tabClicked = (idx: number) => {
            activeTab.value = idx;
        };

        const activateUser = (id: number) => {
            exec(UsersAPI.activateMember(id))
                .then(() => {
                    refetch();
                    (root as any).$toastr.s('Member has been activated successfuly', 'Success');
                })
                .catch(() => {
                    (root as any).$toastr.e('Member could not be activated due to an error.', 'Error');
                });
        };

        const deactivateUser = (id: number) => {
            exec(UsersAPI.deactivateMember(id))
                .then(() => {
                    refetch();
                    (root as any).$toastr.s('Member has been deactivated successfuly', 'Success');
                })
                .catch(() => {
                    (root as any).$toastr.e('Member could not be deactivated due to an error.', 'Error');
                });
        };

        const clearUserInvitation = () => {
            userInvitation.value = {
                firstName: '',
                lastName: '',
                email: '',
                username: '',
            };
        };

        const clearErrors = () => {
            inviteUserError.value = '';
            error.value = null;
        };

        const sendInvitation = async () => {
            userInvitation.value.username = userInvitation.value.email;
            await exec(KeycloakAPI.inviteUser(userInvitation.value))
                .then(() => {
                    inviteUserHidden.value = false;
                    clearUserInvitation();
                    inviteUserError.value = '';
                    (root as any).$toastr.s('Member has been invited successfuly', 'Success');
                })
                .catch((e) => {
                    if (e && e.response && e.response.status === 409) {
                        inviteUserError.value = 'Member with this email has already been invited.';
                    } else {
                        (root as any).$toastr.e('The member could not be invited due to an error', 'Error');
                    }
                });
        };

        return {
            tabs,
            activeTab,
            loading,
            organizationLoading,
            organizationError,
            error,
            departments,
            saveChanges,
            cancel,
            newDepartment,
            countries,
            countriesLoading,
            countriesError,
            cities,
            addDepartment,
            addDepHidden,
            completeOrganization,
            sortedCountries,
            getCities,
            createDepartment,
            newDepartmentRef,
            organizationUsers,
            depUsers,
            organizationDepartments,
            organizationUsersLoading,
            organizationUsersError,
            organizationRef,
            inviteUserHidden,
            inviteUser,
            userInvitation,
            updateDepartment,
            updateDepartmentDetails,
            updateDepHidden,
            editDepartment,
            removeDepartment,
            updateDepartmentRef,
            confirmDelete,
            showDeleteModal,
            showDepartmentsInfo,
            organization,
            organizationRoles,
            tabClicked,
            enableDepartmentsInfo,
            orgLocationCities,
            activateUser,
            deactivateUser,
            user,
            changesExist,
            sendInvitation,
            inviteUserError,
            clearErrors,
            readOnly,
        };
    },
});
